<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId"
                       @change='areaChange'
                       filterable
                       placeholder=""
                       size="10">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.streetId"
                       filterable
                       size="15"
                       placeholder="请选择"
                       @change="$refs.parkInput.setValue('');formInline.operationId = ''">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="2"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <el-select
                  v-model.trim="formInline.parkType"
                  filterable
                  placeholder=""
                  size="15"
                >
                  <el-option label="全部" value="-1"></el-option>
                  <el-option
                    :label="park.desc"
                    :value="park.code"
                    :key="park.code"
                    v-for="park in parkType"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
                <el-select
                  v-model.trim="formInline.entryExitType"
                  filterable
                  size="10"
                >
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ExitTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.time_slot')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item style="margin: 0 15px 0 5px">
                <span
                  @click="timeFormat(0)"
                  class="time_table"
                  :class="active_index == 0 ? 'time_active' : ''"
                  >昨日</span
                >
                <span
                  @click="timeFormat(30)"
                  class="time_table"
                  :class="active_index == 30 ? 'time_active' : ''"
                  >近30日</span
                >
                <span
                  @click="timeFormat(90)"
                  class="time_table"
                  :class="active_index == 90 ? 'time_active' : ''"
                  >近90日</span
                >
                <span
                  @click="timeFormat(180)"
                  class="time_table"
                  :class="active_index == 180 ? 'time_active' : ''"
                  >近180日</span
                >
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
              <el-button icon="el-icon-delete" @click="resetForm"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                v-if="$route.meta.authority.button.export"
                @exportFile="handleExportFile"
                :exportData="exportData"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          border
          stripe
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        class="dialogVisible"
        title="欠费提醒配置"
        :visible.sync="dialogVisible"
        width="450px"
      >
        <el-form
          ref="form"
          @submit.native.prevent="submit"
          :rules="rules"
          :model="arrearsForm"
          label-width="120px"
          style="width: 80%"
        >
          <el-form-item label="欠费提醒金额" prop="debtMoney">
            <el-input
              width="120px"
              maxlength="7"
              placeholder="请输入欠费提醒金额"
              type="text"
              v-model="arrearsForm.debtMoney"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker/timePicker2";
import { dateFormat, setIndex, onlyNumber } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "arrearsRecordTotal",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输十一个字符"));
      }
    };
    return {
      defalutDate: [],
      onlyNumber,
      rules: {
        debtMoney: [
          { required: true, message: "欠费提醒金额不能为空" },
          {
            validator: (rule, value, callback) => {
              this.arrearsForm.debtMoney = this.onlyNumber(value);
              callback();
            },
            trigger: "change",
          },
        ],
      },
      dialogVisible: false,
      arrearsForm: {
        debtMoney: "",
      },
      state2: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      roleList: [],
      plateList: [],
      ExitTypeList: [],
      merchantList: [],
      berthList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "money",
          label: this.$t("list.arrears"),
          width: "100",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "",
        endTime: "",
        name: "停车欠费订单",
        dataSource: 2,
      },
      exportData: {},
      active_index: 1,
      areaIds: "",
    };
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },

    handleExportFile() {
      this.$nextTick(() => {
        let data = JSON.parse(JSON.stringify(this.formInline));
        data.areaId = "";
        if (this.$refs.cascader) {
          data.areaId = this.$refs.cascader.getStreetId()
            ? this.$refs.cascader.getStreetId()
            : this.$refs.cascader.getAreaId();
        }
        // 导出数据
        this.exportData = {
          url: "/acb/2.0/bacb/debtOrder/export",
          methods: "get",
          data: data,
        };
      });
    },

    // 便捷日期
    timeFormat(num) {
      let nowDate = new Date();
      let beforeDate = new Date(nowDate);
      // 昨日
      if (num == 0) {
        this.active_index = 0;
        beforeDate.setDate(nowDate.getDate() - 1);
        // 近30日
      } else if (num == 30) {
        this.active_index = 30;
        beforeDate.setDate(nowDate.getDate() - 30);
        //  近90日
      } else if (num == 90) {
        if (this.formInline.plateNumber == "") {
          this.$alert("请输入您查询的车牌号", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.active_index = 90;
        beforeDate.setDate(nowDate.getDate() - 90);
        // 近180日
      } else if (num == 180) {
        if (this.formInline.plateNumber == "") {
          this.$alert("请输入您查询的车牌号", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.active_index = 180;
        beforeDate.setDate(nowDate.getDate() - 180);
      }
      let year = beforeDate.getFullYear();
      let month =
        beforeDate.getMonth() + 1 < 10
          ? "0" + (beforeDate.getMonth() + 1)
          : beforeDate.getMonth() + 1;
      let day =
        beforeDate.getDate() < 10
          ? "0" + beforeDate.getDate()
          : beforeDate.getDate();
      let dateStr = year + "-" + month + "-" + day;

      if (num == 0) {
        this.defalutDate = [dateStr + " 00:00:00", dateStr + " 23:59:59"];
        this.formInline.startTime = dateStr + " 00:00:00";
        this.formInline.endTime = dateStr + " 23:59:59";
      } else {
        this.defalutDate = [
          dateStr + " 00:00:00",
          this.dataTimeRest() + " 23:59:59",
        ];
        this.formInline.startTime = dateStr + " 00:00:00";
        this.formInline.endTime = this.dataTimeRest() + " 23:59:59";
      }
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    dialogShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.getDebtMoney();
      });
    },
    getDebtMoney() {
      this.$axios.get("/acb/2.0/bigDebtRule/getLatestRecord").then((res) => {
        this.arrearsForm.debtMoney = res.value.debtMoney
          ? (res.value.debtMoney / 100).toFixed(2)
          : "";
      });
    },
    submit() {
      this.$refs.form.validate((v) => {
        if (v) {
          this.dialogVisible = false;
          let debtMoney = parseFloat(
            (this.arrearsForm.debtMoney * 100).toPrecision(12)
          );
          this.$axios
            .post("/acb/2.0/bigDebtRule/create", {
              data: {
                debtMoney,
              },
            })
            .then(() => {
              this.$message.success("操作成功！");
            });
        }
      });
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    resetForm() {
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "",
        endTime: "",
        name: "停车欠费订单",
        dataSource: 2,
      };
      this.areaIds = "";
      this.active_index = 1;
      this.defalutDate = [
        this.dataTimeRest() + " 00:00:00",
        this.dataTimeRest() + " 23:59:59",
      ];
      this.$refs.timeRangePicker.resetTime();
      this.$refs.parkInput.setValue("");
      this.$refs.cascader.clear();
      // let data = JSON.parse(JSON.stringify(this.formInline));
      // data.areaId = "";
      // if (this.$refs.cascader) {
      //   data.areaId = this.$refs.cascader.getStreetId()
      //     ? this.$refs.cascader.getStreetId()
      //     : this.$refs.cascader.getAreaId();
      // }
      // this.$nextTick(() => {
      //   // 导出数据
      //   this.exportData = {
      //     url: '/acb/2.0/debtOrder/export',
      //     methods: 'get',
      //     data: data
      //   }
      // })
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
        //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
        // }
      });
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        var time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31 && this.formInline.carId == "") {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    getChildrenArea() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: this.formInline.areaId,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.carId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            slaveRelations: "0,1",
            dataSource: 1,
            operationId: this.formInline.operationId,
            areaIds: this.$refs.cascader.getStreetId()
              ? this.$refs.cascader.getStreetId()
              : this.$refs.cascader.getAreaId(),
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searchData() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.carId = "";
      }
      let flag = this.showLog();

      if (this.active_index == 90 || this.active_index == 180) {
        if (this.formInline.plateNumber == "") {
          this.$alert("请输入您查询的车牌号", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/bacb/debtOrder/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkType: this.formInline.parkType,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          entryExitType: this.formInline.entryExitType,
          areaId: this.$refs.cascader.getStreetId()
            ? this.$refs.cascader.getStreetId()
            : this.$refs.cascader.getAreaId(),
          operationId: this.formInline.operationId,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          plateNumber: this.formInline.plateNumber,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      if (flag) {
        this.loading = true;
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.$request(opt);
      }
    },
  },
  components: {
    myComponent,
    exportFile,
    timeRangePick,
  },
  created() {
    // this.$route.meta.keepAlive = true;
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData();
    this.getParkSpinner();
    // this.getMerchant();
    // this.getPay();
    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
  },
  mounted() {
    this.searchData();
    this.exportData = {
      url: "/acb/2.0/bacb/payOrder/exportPayOrder",
      methods: "post",
      data: this.formInline,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.time_table {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

.time_active {
  color: #409EFF;
}
</style>
